    import { BrowserModule } from '@angular/platform-browser';
    import { NgModule } from '@angular/core';
    import { AppRoutingModule} from './app-routing.module';
    import { AppComponent } from './app.component';
    import { SidebarComponent } from './Nav/sidebar/sidebar.component';
    import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
    import { RegisterModule } from './Components/register/register.module';
    import { SharedModule } from './Components/shared/shared.module';
    import { NavbarService } from './Nav/navbar.service';
    import { CoreModule } from '../app/Service/core/core.module';
   
    import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
    import { CountUpModule } from 'ngx-countup';

  
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// import { SupDashboardComponent } from './Components/sup-dashboard/sup-dashboard.component';
// import { SDashboardComponent } from './Components/s-dashboard/s-dashboard.component';
// import { MainDashboardComponent } from './Components/main-dashboard/main-dashboard.component';
// import { IdleReportComponent } from './Components/idle-report/idle-report.component';
// import { NgxCaptureModule } from 'ngx-capture'



    
    @NgModule({
     declarations: [
      AppComponent,
        SidebarComponent,
       
        // SupDashboardComponent,
        // SDashboardComponent,
        // MainDashboardComponent,
        // IdleReportComponent,
 
      
    
      
  ],
     imports: [
       BrowserModule,
     AppRoutingModule,HttpClientModule,
        BrowserAnimationsModule,
        RegisterModule, SharedModule,
       CoreModule,
  
       CountUpModule,
      //  NgxCaptureModule,
       ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })      
    
 ],
     providers: [NavbarService],
     bootstrap: [AppComponent]
    })
    export class AppModule { }