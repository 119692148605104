import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http:HttpClient) { }

  signin(login):Observable<any> {
      return this.http.post('login', login);
  }
  machine_count():Observable<any> {
    return this.http.get('machine_count')
  }

  true():Observable<any> {
    return this.http.get('check_status')
  }

//  export(){
//   return this.http.get('export')
//  }

  // forgot(params):Observable<any> {
  //   return this.http.get('sessions/forgot_pwd='+params)
  // }
  machinelist():Observable<any> {
    return this.http.get('hmi_macs');
}
idlereasonlist():Observable<any> {
  return this.http.get('hmi_idles');
}
macroentry(data):Observable<any> {
  return this.http.post('entry',data);
}

tabdata(machine):Observable<any> {
  return this.http.get('details?machine='+machine)
}

getsets(name){
  return this.http.get('mac_setting_list?L1Name='+name+'')
    }
  
    pageservice(val: any): string {
      if(val === "Andon Dashboard") {
        return "/main-dashboard";
      } else if(val === "Live OEE") {
        return "/Oee-dashboard";
      } else if(val === "Cycle Time Chart") {
        return "/cycle-time-chart";
      } else if(val === "Oee Report") {
        return "/oee-report";
      } else if(val === "Operator Efficiency") {
        return "/operator-efficiency";
      } else if(val === "General Report") {
        return "/generalreport";
      } else if(val === "Idle Reason Report") {
        return "/idle_report";
      } else if(val === "Compare Chart") {
        return "/Overallcomparechart";
      } else if(val === "Quality Entry") {
        return "/newquality";
      } else if(val === "Machine") {
        return "/machine_registration";
      } else if(val === "Shift") {
        return "/shift";
      } else if(val === "User") {
        return "/user_management";
      } else if(val === "Component") {
        return "/component";
      } else if(val === "Operator") {
        return "/operator_registration";
      } else if(val === "Reason") {
        return "/reason_reg";
      }
       else {
        return "/default-page"; // Default return value for cases not handled above
      }
    }


    pageService1(val: any): string {
      if (val === "/main-dashboard") {
        return "Andon Dashboard";
      } else if (val === "/Oee-dashboard") {
        return "Live OEE";
      } else if (val === "/cycle-time-chart") {
        return "Cycle Time Chart";
      } else if (val === "/oee-report") {
        return "Oee Report";
      } else if (val === "/operator-efficiency") {
        return "Operator Efficiency";
      } else if (val === "/generalreport") {
        return "General Report";
      } else if (val === "/idle_report") {
        return "Idle Reason Report";
      } else if (val === "/Overallcomparechart") {
        return "Compare Chart";
      } else if (val === "/newquality") {
        return "Quality Entry";
      } else if (val === "/machine_registration") {
        return "Machine";
      } else if (val === "/shift") {
        return "Shift";
      } else if (val === "/user_management") {
        return "User";
      } else if (val === "/component") {
        return "Component";
      } else if (val === "/operator_registration") {
        return "Operator";
      } else if (val === "/reason_reg") {
        return "Reason";
      } else {
        return ""; // Default return value for cases not handled above
      }
    }
    
}
